<template>
  <div class="row">
    <div class="col-12 text-center g" v-if="loading">
        <img
        :src="require('@/assets/images/loading.svg')"
        style="width: 150px; height: 150px"
        alt=""
        />
        <br><br>
    </div>
    <div class="col-12 col-lg-6 c">
        <div class="card">
            <div class="card-body">
                <p>
                    اختر الفئات المستهدفة
                </p>
                <div class="row">
                    <div class="col-12" v-for="tag in obj" :key="tag.name">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" v-model="selectedtags" :value="tag.name">
                            <span v-if="tag.name != 'etc'">{{ tag.name }}</span> <span v-if="tag.name == 'etc'">غير مصنف / أخرى</span>  ({{ tag.value }})
                          </label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="form-group">
                  <label for="">JWT</label>
                  <input type="text"
                    class="form-control" v-model="sendjwt">
                </div>
                <div class="form-group">
                  <label for="">Devices ( split with , )</label>
                  <input type="text"
                    class="form-control" v-model="senddevices" placeholder="1234,3435">
                </div>
                <div class="form-group">
                  <label for="">الرسالة</label>
                  <textarea class="form-control" v-model="message" rows="3"></textarea>
                </div>
                <div class="form-group">
                  <label for="">رابط الملف</label>
                  <input type="url"
                    class="form-control" v-model="file">
                </div>
                <div class="form-group">
                  <label for="">المدة بين كل رسالة (بالثواني)</label>
                  <input type="number"
                    class="form-control" v-model="sendinterval">
                </div>
                <div class="col-12 text-center">
                    <span v-if="loading2" class="btn btn-outline-danger">جاري المعالجة...</span>
                    <button v-if="!loading2" class="btn btn-primary" @click="createNow()">
                        بدء المعجنة
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            selectedtags: [],
            message: "",
            file: "",
            sendjwt: "",
            senddevices: "",
            sendinterval: 60,
            loading2: false,
            obj: [],
            user: JSON.parse(localStorage.getItem("user")),
        }
    },
    methods: {
        createNow(){
            var g = this;
            g.loading2 = true;
            $.post(marketing + "/create-operation", {
                jwt: g.user.jwt,
                file: g.file,
                message: g.message,
                tags: g.selectedtags,
                send_jwt: g.sendjwt,
                devices: g.senddevices,
                interval: g.sendinterval
            }).then(function(r){
                window.location = '/marketing/list'
            }).catch(function(e){
                alert("error " + e)
            })
        }
    },
    created(){
        var g = this;
        $.post(marketing + "/stats", {
            jwt: this.user.jwt,
        }).then(function(a){
            function compare( a, b ) {
                if ( a.value < b.value ){
                    return 1;
                }
                if ( a.value > b.value ){
                    return -1;
                }
                return 0;
            }
            for (const [key, value] of Object.entries(a.obj)) {
                g.obj.push({
                    name: key,
                    value: value
                })
            }
            g.obj.sort( compare );
            g.loading = false
    })
    }
}
</script>

<style>

</style>